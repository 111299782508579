export const coinData = [
  {
    value: 336
  },
  {
    value: 16
  },
  {
    value: 383
  },
  {
    value: 216
  },
  {
    value: 20
  },
  {
    value: 336
  },
  {
    value: 16
  },
  {
    value: 383
  },
  {
    value: 216
  },
  {
    value: 20
  },
  {
    value: 16
  },
  {
    value: 383
  },
  {
    value: 216
  }
]
